@import url("https://fonts.googleapis.com/css2?family=Jost:wght@300&family=Satisfy&display=swap");
.MyApp {
  text-align: center;
  background-color: #ffffff;
  font-family: "Jost", sans-serif;
  position: relative;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  /* margin: auto; */
  position: absolute;
}

.responsive {
  min-height: 80%;
  max-width: 100%;
  background-size: cover;
}

.MyApp-header {
  background-color: #ffffff;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0) !important;
}

.MyApp-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.eating {
  width: 100%;
  height: auto;
}

.invitation {
  width: 50%;
  height: auto;
  text-align: justify;
}

.container2 {
  display: flex;
  justify-content: center;
}

.AddResources {
  width: 50%;
}
/* Container holding the image and the text */
.containerIT {
  position: relative;
  text-align: center;
  color: white;
  height: 80rem;
  background-image: url(../images/new.jpg);
  background-size: cover;
  background-position: center;
}

/* Bottom left text */
.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
}

/* Top left text */
.top-left {
  position: absolute;
  top: 8px;
  left: 16px;
}

/* Top right text */
.top-right {
  position: absolute;
  top: 8px;
  right: 16px;
}

/* Bottom right text */
.bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
}

.bottom {
  position: absolute;
  bottom: 0px;
  height: 10%;
  background-color: white;
  width: 100%;
  opacity: 0.8;
  color: black;
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centre-top {
  position: absolute;
  top: 18%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-left: auto;
  margin-right: auto;
}

.centre-top-top {
  position: absolute;
  top: 6%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.h1onwhite {
  color: black;
  font-family: "Jost", sans-serif;
  font-size: 400%;
}

.h2onwhite {
  color: black;
  font-family: "Jost", sans-serif;
  font-size: 300%;
}
.MyNavBar {
  list-style-type: none;
  padding-left: 0;
  padding-top: 9px;
  margin-bottom: 5px;
  display: flex;
  text-align: left;
  width: 100%;
  justify-content: space-evenly;
  font-family: "Jost", sans-serif;
  flex-wrap: wrap;
}

.MyNavBar h1 {
  font-size: 1.5rem;
  font-weight: bold !important;
}

.MyNavBar a:link {
  color: rgba(51, 3, 51, 0.597);
  text-decoration: none;
}

.MyNavBar a:visited {
  color: rgba(51, 3, 51, 0.597);
  text-decoration: none;
}

.MyNavBar a:hover {
  color: violet;
  text-decoration: none;
}

.MyNavBar a:active {
  text-decoration: none;
}

textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  width: 60%;
}

.footer {
  background-color: rgba(0, 0, 0, 0.934);
  height: 3rem;
  margin: 0;
  color: white;
  display: flex;
  padding: 0px 0px 0.2rem 1rem;
  position: absolute;
  width: 100%;
  bottom: 0;
}

/* #pageContainer {
  position: relative;
  min-height: 100vh;
} */
.Preferences > select {
  max-width: 50%;
}
.content-wrap {
  padding-bottom: 3rem; /* Footer height */
}

/* .password {
  height: h;
} */

@media only screen and (max-width: 450px) {
  .containerIT {
    position: relative;
    text-align: center;
    color: white;
    height: 80rem;
    background-image: url(../images/new.jpg);
    background-size: cover;
    background-position: 25% 75%;
  }
  .h2onwhite {
    margin-top: 20rem;
  }
  .h1onwhite {
    font-size: 300%;
    /* margin: 10rem; */
  }
  .MyNavBar h1 {
    font-size: 1rem;
  }
  .invitation {
    width: 85%;
  }
  .AddResources {
    width: 85%;
  }

  .banana {
    margin: 14px 0px 10px 0px;
    text-align: justify center;
  }

  .Preferences > select {
    max-width: 90%;
  }

  textarea {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    width: 90%;
  }
}

.footer p {
  margin: 14px;
}
